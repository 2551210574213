<template>
  <v-row class="d-flex align-center pa-4 pb-0">
    <v-col cols="12" class="d-flex justify-end justify-md-center align-center">
      <v-btn :disabled="loading" class="mr-2" icon @click="onYearChange(+params.year - 1)">
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <span class="text-h6">
        {{ params.year }}
      </span>
      <v-btn :disabled="loading" class="ml-2" icon @click="onYearChange(+params.year + 1)">
        <v-icon>chevron_right</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import filterMixin from '@/mixins/filter-mixin';

export default {
  name: 'OrderStatisticsByDateFilter',

  mixins: [filterMixin],

  props: {
    filterParams: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      params: {},
    };
  },

  methods: {
    onYearChange(value) {
      this.params.year = value;
      this.submitFilters();
    },
  },
};
</script>

<style scoped></style>
