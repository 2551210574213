<template>
  <div>
    <OrderStatisticsByDateFilter
      :filter-params="orderStatisticsByDateFilterParams"
      :loading="loading['get:api/order-statistics']"
      @change="$router.push({ name: 'orderStatisticsByDate', query: $event })"
    />

    <div :class="{ 'content-loading': loading['get:api/order-statistic'] }">
      <div class="pa-3">
        <HorizontalBarChart :chart-data="chartData" />
      </div>
    </div>
  </div>
</template>

<script>
import OrderStatisticsByDateFilter from '@/components/OrderStatisticsByDateFilter';
import { mapActions, mapGetters, mapState } from 'vuex';
import { getSanitizedFilterParams } from '@/util/filter-params';
import HorizontalBarChart from '@/charts/HorizontalBarChart';
import { format, setMonth, setYear } from 'date-fns';

export default {
  name: 'OrderStatisticsByDate',

  components: { HorizontalBarChart, OrderStatisticsByDateFilter },

  computed: {
    ...mapState('orderStatisticsByDate', [
      'orderStatisticsByDate',
      'orderStatisticsByDateFilterParams',
    ]),
    ...mapGetters(['loading']),
    ...mapGetters('settings', ['defaultDateFnsConfig']),

    chartData() {
      const labels = [];
      const data = [];

      const map = {};

      for (let i = 0; i < this.orderStatisticsByDate.length; i++) {
        const { total_price, year, month } = this.orderStatisticsByDate[i];
        map[`${year}-${month}`] = total_price;
      }

      for (let i = 0; i < 12; i++) {
        const date = setMonth(setYear(new Date(), this.orderStatisticsByDateFilterParams.year), i);
        labels.push(format(date, 'LLLL', this.defaultDateFnsConfig));
        data.push(map[format(date, 'yyyy-M', this.defaultDateFnsConfig)] || 0);
      }

      return {
        labels,
        datasets: [
          {
            label: this.$t('general.orders_total'),
            backgroundColor: this.$vuetify.theme.currentTheme.success,
            data,
          },
        ],
      };
    },
  },

  created() {
    this.fetchOrderStatisticsByDate(this.$route.query);
  },

  beforeRouteUpdate(to, from, next) {
    const params = getSanitizedFilterParams(to.query);
    if (
      JSON.stringify(this.orderStatisticsByDateFilterParams) !== JSON.stringify(params) &&
      to.name === 'orderStatisticsByDate'
    ) {
      this.fetchOrderStatisticsByDate(to.query);
    }
    next();
  },

  methods: {
    ...mapActions('orderStatisticsByDate', ['fetchOrderStatisticsByDate']),
  },
};
</script>

<style scoped></style>
