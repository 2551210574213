<template>
  <div>
    <div class="d-flex align-center justify-end px-4">
      <ContactOrderStatisticsFilter
        :filter-params="contactOrderStatisticsFilterParams"
        @change="$router.push({ name: 'contactOrderStatistics', query: $event })"
        @reset="resetContactOrderStatisticsFilters"
      />
    </div>

    <div :class="{ 'content-loading': loading['get:api/project-issues-statistic'] }">
      <div class="pa-3">
        <HorizontalBarChart :chart-data="chartData" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { getSanitizedFilterParams } from '@/util/filter-params';
import HorizontalBarChart from '@/charts/HorizontalBarChart';
import ContactOrderStatisticsFilter from '@/components/filters/ContactOrderStatisticsFilter';
import { getDefaultContactOrderStatisticsFilterParams } from '@/store/modules/contact-order-statistics-module';

export default {
  name: 'contactOrderStatistics',

  components: { ContactOrderStatisticsFilter, HorizontalBarChart },

  computed: {
    ...mapState('contactOrderStatistics', [
      'contactOrderStatistics',
      'contactOrderStatisticsFilterParams',
    ]),
    ...mapGetters(['loading']),

    chartData() {
      const labels = [];
      const orders = [];

      for (let i = 0; i < this.contactOrderStatistics.length; i++) {
        const contact = this.contactOrderStatistics[i];

        labels.push(contact.full_name || contact.name || '-');
        orders.push(contact.total_price);
      }
      return {
        labels,
        datasets: [
          {
            label: this.$t('general.orders_total'),
            backgroundColor: this.$vuetify.theme.currentTheme.success,
            data: orders,
          },
        ],
      };
    },
  },

  created() {
    this.fetchContactOrderStatistics(getSanitizedFilterParams(this.$route.query));
  },

  beforeRouteUpdate(to, from, next) {
    const params = getSanitizedFilterParams(to.query);
    if (
      JSON.stringify(this.contactOrderStatisticsFilterParams) !== JSON.stringify(params) &&
      to.name === 'contactOrderStatistics'
    ) {
      this.fetchContactOrderStatistics(to.query);
    }
    next();
  },

  methods: {
    ...mapActions('contactOrderStatistics', ['fetchContactOrderStatistics']),

    resetContactOrderStatisticsFilters() {
      const defaultFilters = getDefaultContactOrderStatisticsFilterParams();
      if (
        JSON.stringify(defaultFilters) === JSON.stringify(this.contactOrderStatisticsFilterParams)
      ) {
        return;
      }
      this.$router.push({ name: 'contactOrderStatistics', query: defaultFilters });
    },
  },
};
</script>

<style scoped></style>
